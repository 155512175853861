import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default function EmailSubmitted() {
	return (
		<Layout>
			<SEO title="Form submitted" />
			<div className="p-4 my-4">
				<h1 className="text-2xl">Thanks for your interest!</h1>
				<p>We'll be in touch soon with more information.</p>
			</div>
		</Layout>
	);
}
